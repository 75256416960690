// export const APPSFLYER_ONELINK_WEB_TO_APP_URL =
//     'https://listen.noice.id/cyOg/t3auukl5'

export const APPSFLYER_ONELINK_WEB_TO_APP_URL =
    'https://noiceid.onelink.me/cyOg/xw8hfyxp'

export const APPSFLYER_ONELINK_WEB_TO_APP_BUKA_URL =
    'https://noiceid.onelink.me/cyOg/kumtoiwa'

// Catalog Type 
export const SERIES = 'audioseries'